import Link from 'next/link';
import { useMemo } from 'react';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { BreakingNewsQuery } from '@news/gql';
import { useLatestBreakingNewsQuery } from '@news/gql';
import { GroupId } from '@news/tracking';

import { BreakingNewsElement } from 'components/BreakingNewsElement';
import { Typography } from 'components/Typography';
import { PrimaryButton } from 'components/buttons';
import { Chevron } from 'components/icons';
import { Label } from 'components/labels';
import { BreakingNewsWidgetLoading } from 'components/loading';
import { BreakingNewsContextProvider } from 'contexts/BreakingNewsContext';
import { breakingNewsPageDetails } from 'lib/pageDetails';
import { squareCardBorderRadius } from 'styles/mixins/borderRadius';
import { feedItemBoxShadow } from 'styles/mixins/boxShadow';
import { theme } from 'styles/theme';

export const BreakingNewsWidget = () => {
  const { data, loading, called } = useLatestBreakingNewsQuery({
    variables: {
      skip: 0,
      limit: 10,
    },
    fetchPolicy: 'cache-first',
    pollInterval: 30 * 1000, // Poll every 30 seconds
  });
  const breakingNews = data?.latestBreakingNews.items;

  if (loading || !called) return <BreakingNewsWidgetLoading />;

  return <BreakingNewsWidgetComponent breakingNews={breakingNews} />;
};

const BreakingNewsWidgetComponent = ({ breakingNews }: { breakingNews?: BreakingNewsQuery['breakingNews'] }) => {
  const threeLatestBreakingNews = useMemo(() => breakingNews?.slice(0, 3) || [], [breakingNews]);

  if (!breakingNews || breakingNews.length === 0) {
    return null;
  }

  return (
    <BreakingNewsWidgetWrapper>
      <BreakingNewsLabel>Senaste nytt</BreakingNewsLabel>
      <BreakingNewsContextProvider>
        {threeLatestBreakingNews.map((news, index) => {
          return (
            <BreakingNewsElement
              key={news.id}
              item={news}
              compactOnMobile
              showArrow
              disableImpressionTracking
              positionData={{
                index: index,
                groupId: GroupId.BreakingNewsModule,
                kilkayaPositionId: 'breakingNewsModule',
              }}
            />
          );
        })}
      </BreakingNewsContextProvider>

      <ButtonWrapper href={breakingNewsPageDetails.page} shallow={true} passHref>
        <PrimaryButton>
          <Typography variant="CTA">Senaste dygnet</Typography>
          <Chevron direction="right" />
        </PrimaryButton>
      </ButtonWrapper>
    </BreakingNewsWidgetWrapper>
  );
};

const BreakingNewsWidgetWrapper = styled.div`
  ${feedItemBoxShadow}
  background-color: ${colors.white[100]};

  ${theme.mq.tablet} {
    ${squareCardBorderRadius}
  }
`;

const BreakingNewsLabel = styled(Label)`
  margin: ${sizeUnits[16]} ${sizeUnits[16]} 0;
`;

const ButtonWrapper = styled(Link)`
  display: flex;
  padding: ${sizeUnits[12]} ${sizeUnits[16]};
  color: ${colors.black[100]};
  ${PrimaryButton} {
    width: 100%;
  }
`;
