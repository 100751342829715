import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { BreakingNewsFieldsFragment } from '@news/gql';
import { slugify } from '@news/lib';

import { breakingNewsPageDetails } from 'lib/pageDetails';
import { PREVENT_COLLAPSE_CLASS, isSelectingText } from 'views/feed/helpers';

import { usePageContext } from './PageContext';

export type BreakingNewsWithFocused = BreakingNewsFieldsFragment & { isFocused?: boolean };

interface IBreakingNewsContext {
  onBreakingNewsClick: (event: React.MouseEvent, item: BreakingNewsWithFocused) => void;
  selectedBreakingNews: BreakingNewsWithFocused | undefined;
  viewedBreakingNews: string[];
}

const BreakingNewsContext = createContext<IBreakingNewsContext | undefined>(undefined);

const VIEWED_STORAGE_ID = 'viewed-breaking-news';

type BreakingNewsProviderProps = React.PropsWithChildren<{
  updatePathOnExpanded?: boolean;
  expandedItem?: BreakingNewsWithFocused;
}>;

export const BreakingNewsContextProvider = ({
  children,
  expandedItem,
  updatePathOnExpanded = false,
}: BreakingNewsProviderProps) => {
  const router = useRouter();
  const { sendPageEvent } = usePageContext();
  const [selectedBreakingNews, setSelectedBreakingNews] = useState<BreakingNewsWithFocused | undefined>(expandedItem);
  const [viewedBreakingNews, setViewedBreakingNews] = useState<string[] | undefined>();

  const onBreakingNewsClick = useCallback(
    (event: React.MouseEvent, item: BreakingNewsWithFocused) => {
      if (isSelectingText() || (event.target as HTMLElement).closest(`.${PREVENT_COLLAPSE_CLASS}`)) {
        return;
      }

      if (isSameBreakingNews(item, selectedBreakingNews)) {
        // Close the expanded news
        setSelectedBreakingNews(undefined);
        if (updatePathOnExpanded) {
          router.replace(breakingNewsPageDetails.page, undefined, { shallow: true });
        }
        return;
      }
      setSelectedBreakingNews(item);
      const cleanTitle = slugify(item.title ?? '');
      const itemPath = breakingNewsPageDetails.itemPath(item.id, cleanTitle);
      if (updatePathOnExpanded) {
        router.replace(itemPath, undefined, { shallow: true });
      }

      sendPageEvent({
        pageData: {
          page_id: item.id,
          page_path: itemPath,
          page_title: item.title ?? '',
          page_type: 'breaking-news',
          page_feed: '/just-nu',
          page_tags: null,
        },
        kilkayaData: {
          // No image for breaking news
          imageUrl: undefined,
          publishDate: item?.publishedAt || undefined,
        },
      });
    },
    [router, selectedBreakingNews, sendPageEvent, updatePathOnExpanded]
  );

  useEffect(() => {
    if (!selectedBreakingNews || viewedBreakingNews === undefined) {
      return;
    }
    if (!viewedBreakingNews.includes(selectedBreakingNews.id)) {
      const newViewedNews = [...viewedBreakingNews, selectedBreakingNews.id];
      if (newViewedNews.length > 50) {
        newViewedNews.shift();
      }
      setViewedBreakingNews(newViewedNews);
      localStorage.setItem(VIEWED_STORAGE_ID, JSON.stringify(newViewedNews));
    }
  }, [selectedBreakingNews, viewedBreakingNews]);

  useEffect(() => {
    const viewedItems = localStorage.getItem(VIEWED_STORAGE_ID);
    if (viewedItems) {
      setViewedBreakingNews(JSON.parse(viewedItems));
      return;
    }
    setViewedBreakingNews([]);
  }, []);

  const value: IBreakingNewsContext = useMemo(
    () => ({ onBreakingNewsClick, selectedBreakingNews, viewedBreakingNews: viewedBreakingNews ?? [] }),
    [onBreakingNewsClick, selectedBreakingNews, viewedBreakingNews]
  );

  return <BreakingNewsContext.Provider value={value}>{children}</BreakingNewsContext.Provider>;
};

export const useBreakingNewsContext = (): IBreakingNewsContext => {
  const context = useContext(BreakingNewsContext);
  if (!context) {
    throw new Error('useBreakingNewsContext must be used within a BreakingNewsProvider');
  }
  return context;
};

export const isSameBreakingNews = (a: BreakingNewsWithFocused, b: BreakingNewsWithFocused | undefined) => {
  if (!b) {
    return false;
  }
  return a.id === b.id && a.isFocused === b.isFocused;
};
