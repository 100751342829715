import Link from 'next/link';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import type { PositionData } from 'tracking/ItemTracker';
import { ItemTracker } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { getItemUrlForFeedItem, getRelativeTimeFromNow, isNotNullish } from '@news/lib';

import type { BreakingNewsWithFocused } from 'contexts/BreakingNewsContext';
import { isSameBreakingNews, useBreakingNewsContext } from 'contexts/BreakingNewsContext';
import { isMobile } from 'lib/helpers';
import { articlePageDetails } from 'lib/pageDetails';
import { theme } from 'styles/theme';
import { BodyWrapper } from 'views/feed/components/BodyWrapper';
import { ArticleFooter } from 'views/feed/components/article/ArticleFooter';

import { Typography } from './Typography';
import { Chevron } from './icons';

type Props = {
  item: BreakingNewsWithFocused;
  showArrow?: boolean;
  compactOnMobile?: boolean;
  disableImpressionTracking?: boolean;
  positionData?: PositionData;
};

export const BreakingNewsElement = React.memo(function BreakingNewsElement({
  item,
  compactOnMobile,
  positionData,
  showArrow = false,
  disableImpressionTracking = false,
}: Props) {
  const { viewedBreakingNews, selectedBreakingNews, onBreakingNewsClick } = useBreakingNewsContext();
  const [mobile, setMobile] = useState(true);
  const [humanTimeSincePublish, setHumanTimeSincePublish] = useState<string | undefined>(undefined);
  const path = getItemUrlForFeedItem(item) ?? '/';

  const isExpanded = isSameBreakingNews(item, selectedBreakingNews);
  const isRead = useMemo(() => viewedBreakingNews.includes(item.id), [item.id, viewedBreakingNews]);

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      onBreakingNewsClick(event, item);
    },
    [onBreakingNewsClick, item]
  );

  useEffect(() => {
    if (!isMobile()) {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    const firstPublishedAt = item?.firstPublishedAt;
    setHumanTimeSincePublish(firstPublishedAt ? getRelativeTimeFromNow(Date.parse(firstPublishedAt)) : undefined);
    const interval = setInterval(() => {
      setHumanTimeSincePublish(firstPublishedAt ? getRelativeTimeFromNow(Date.parse(firstPublishedAt)) : undefined);
    }, 10000);
    return () => clearInterval(interval);
  }, [item?.firstPublishedAt]);

  const nodeData = useMemo(() => item.data?.filter(isNotNullish), [item.data]);

  // Only compact if not expanded, compact on mobile is true, and on mobile
  const compact = !isExpanded && compactOnMobile && mobile;
  // Related article
  const readMoreLink = item.articleId ? articlePageDetails.itemPath(item.articleId) : undefined;

  return (
    <ItemTracker
      disableImpressionTracking={disableImpressionTracking}
      disableClickTracking={isExpanded}
      item={item}
      positionData={positionData}
    >
      <BreakingNewsElementWrapper
        onClick={onClick}
        $isExpanded={isExpanded}
        $isFocused={item.isFocused}
        data-is-focused={item.isFocused ?? 'false'}
      >
        <Metadata variant="metadata" as="span">
          {
            // Insert a zero-width space while we wait for the humanTimeSincePublish to be set, to avoid layout shift
            humanTimeSincePublish ? (
              <>
                <UnreadIndicator $read={!!isRead}></UnreadIndicator>
                {humanTimeSincePublish}
              </>
            ) : (
              '\u200B'
            )
          }
        </Metadata>

        <Title variant="title3-strong" as="h3">
          {item.title}
        </Title>
        <ContentWrapper>
          <ExpandChevron $show={showArrow} size={22} direction={isExpanded ? 'down' : 'right'} />

          <BodyAndFooterContainer>
            {!compact && (
              <LineClampWrapper $isExpanded={isExpanded} $numberOfLines={2}>
                <BodyWrapper body={item.body} data={nodeData} />
                {isExpanded && readMoreLink && (
                  <Typography variant="body1">
                    <Link href={readMoreLink}>Läs mer i artikel</Link>
                  </Typography>
                )}
              </LineClampWrapper>
            )}
            {isExpanded && (
              <ArticleFooter
                sharePath={path}
                byline={item.byline}
                bylineEmail={item.bylineEmail}
                bylineName={item.bylineName}
              />
            )}
          </BodyAndFooterContainer>
        </ContentWrapper>
      </BreakingNewsElementWrapper>
    </ItemTracker>
  );
});
const Title = styled(Typography)`
  color: ${colors.black[100]};
  padding-top: 8px;
`;

const LineClampWrapper = styled.div<{ $isExpanded: boolean; $numberOfLines: number }>`
  ${({ $isExpanded, $numberOfLines }) =>
    $isExpanded
      ? css`
          p:first-of-type {
            margin-top: 0;
          }
        `
      : css`
          -webkit-line-clamp: ${$numberOfLines};
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          max-width: 100%;

          p {
            display: inline;
          }

          p::after {
            content: ' ';
          }

          * :not(p, a) {
            display: none;
          }
        `}
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: ${sizeUnits[4]};
  column-gap: ${sizeUnits[8]};
`;

const BodyAndFooterContainer = styled.div`
  flex-grow: 1;
`;

const UnreadIndicator = styled.span<{ $read: boolean }>`
  height: ${sizeUnits[4]};
  width: ${sizeUnits[4]};
  margin-right: ${sizeUnits[4]};
  margin-bottom: 2px;
  border-radius: 50%;
  background-color: ${({ $read }) => ($read ? colors.black[70] : colors.red.tv4)};
`;

const Metadata = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.black[70]};
`;

const separator = (isExpanded: boolean, isFocused: boolean | undefined) => {
  if (isFocused) {
    return;
  } else {
    return isExpanded ? bottomBorderExpanded : bottomBorderCollapsed;
  }
};

const bottomBorder = css`
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    left: ${sizeUnits[16]};
    right: ${sizeUnits[16]};
    bottom: 0;
  }
`;
const bottomBorderExpanded = css`
  ${bottomBorder}
  &::after {
    background-color: ${colors.red.tv4};
  }
`;

const bottomBorderCollapsed = css`
  ${bottomBorder}
  &::after {
    background-color: ${colors.black[10]};
  }
`;

const BreakingNewsElementWrapper = styled.div<{ $isExpanded: boolean; $isFocused: boolean | undefined }>`
  cursor: pointer;
  width: 100%;
  padding: ${sizeUnits[16]} ${sizeUnits[16]} ${sizeUnits[8]};
  background-color: ${colors.white[100]};
  position: relative;
  ${({ $isExpanded, $isFocused }) => separator($isExpanded, $isFocused)}
`;

const ExpandChevron = styled(Chevron)<{ $show: boolean }>`
  display: none;
  flex-shrink: 0;
  color: ${colors.black[70]};
  ${theme.mq.tablet} {
    ${({ $show }) => $show && 'display: block;'}
  }
`;
